import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./header.scss"
import Social from "../social/social";

const pages = [
  {
    title: 'Блог',
    url: 'blog'
  },
  {
    title: 'Обо мне',
    url: 'about'
  }
];

const Header = ({ page, url, size }) => (

  <header background="#000" className={`${size}`}>
    <nav className="main-menu">
      <div className="content columns photo-and-links-row">
        <a href="/" className="profile light">
          <picture className="photo">
            <img src="https://habrastorage.org/getpro/habr/avatars/434/9b3/feb/4349b3feb09a68ae789329ddbef9f253.png" alt="Max's avatar" />
          </picture>
          <span className="name hide-sm-600">Максим Дубровкин</span>
          <span className="name show-sm-600">Максим Д.</span>
        </a>
        <div className="menus">
          <div className="main">
            {pages.map(pageItem => {
              return <Link
                key={pageItem.url}
                to={`/${pageItem.url}`}
                className={`menu-item light ${pageItem.url === url && 'selected'}`}>
                {pageItem.title}
              </Link>
            })}
            <div className="content columns hide-sm-500">
              <Social />
            </div>
          </div>
        </div>
      </div>

    </nav>
    {size === 'big' &&
      <div className="header-content">
        <h1 className="home">Full Stack C# и JavaScript разработчик</h1>
        <div className="info">
          Увлеченный разработчик программного обеспечения и веб-приложений, специализирующийся на технологиях C#. Мне интересно изучать новые инструменты и подходы, создавать эффективные и масштабируемые решения. Здесь я делюсь своими идеями, опытом программирования и другими интересными вещами.
        </div>
      </div>}
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
