import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../header/header";
import "../../scss/reset.scss";
import "../../scss/common.scss";
import "./layout.scss";
import Social from "../social/social";

const Layout = ({ children, page, url }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
        ...SiteInformation
    }
  `)

  return (
    <>
      <Header
        size={url === 'home' ? 'big' : 'small'}
        siteTitle={data.site.siteMetadata.title}
        page={page}
        url={url} />
      <div>
        <main className={url}>{children}</main>
        <footer>
          <div className="content columns">
            <div>
              © 2019–2024 Максим Дубровкин
              <div style={{maxWidth:'80%'}}>
                Все права защищены и принадлежат их владельцу. Копирование материалов данного блога допускается только с разрешения автора и с указанием первоисточника
              </div>
            </div>
            <div>
              <Social />
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
