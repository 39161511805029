import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faReact, faTwitter, faLinkedin, faGithub, faStackOverflow } from '@fortawesome/free-brands-svg-icons';
import "./social.scss";

const Social = () => {
  return (
    <div className="social-links">
      <a aria-label="Link to LinkedIn" href="https://www.linkedin.com/in/maxim-dubrovkin-9036a825/" rel="noopener noreferrer" target="_blank"
        className="light">
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
      <a aria-label="Link to Habr.com" href="https://habr.com/ru/users/Dubrovkinmaxim/" target="_blank" rel="noopener noreferrer" className="light">
        <FontAwesomeIcon icon={faReact} />
      </a>
      <a aria-label="Link to GitHub" href="https://github.com/dubrovkinmaxim" target="_blank" rel="noopener noreferrer" className="light">
        <FontAwesomeIcon icon={faGithub} />
      </a>
    </div>
  )
}


export default Social
